import {RouteModel} from './route-model';
import {AircraftModel} from './aircraft-model';

export class RouteArrayModel {
  private _routes: RouteModel[] = [];


  constructor() {
  }

  get routes(): RouteModel[] {
    return this._routes;
  }

  updateRoutes() {
    for (const route of this._routes) {
      route.updatePath();
    }
  }

  addRoute(route: RouteModel) {
    this._routes.push(route);
  }

  removeRoute(targetedAircraft: AircraftModel) {
    this._routes = this._routes.filter(x => x.targetedAircraft !== targetedAircraft);
  }
}
