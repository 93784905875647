// Not currently used.

import { Component, OnInit } from '@angular/core';
import {AircraftComponent} from '../aircraft/aircraft.component';
import {AircraftDataService} from '../../../model/services/aircraft-data.service';

@Component({
  selector: 'app-asset',
  templateUrl: './asset.component.html',
  styleUrls: ['./asset.component.css']
})
export class AssetComponent extends AircraftComponent implements OnInit {

  constructor(aircraftDataService: AircraftDataService) {
    super(aircraftDataService);
  }

  ngOnInit() {
  }

}
