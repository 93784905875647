/*
The aircraft model provides the structure for aircraft data.
*/

import {AircraftPositionModel} from './aircraft-position-model';

export class AircraftModel {
  public icao24: string;
  public callSign: string;
  protected airline: string;
  protected year: number;
  protected model: string;
  protected maxSpeed: number;
  public targeted: boolean;
  public position: AircraftPositionModel;

  constructor(icao24: string, callSign: string, latitude: number, longitude: number, airline?: string, year?: number, model?: string, maxSpeed?: number) {
    this.icao24 = icao24;
    this.callSign = callSign;
    this.airline = airline;
    this.year = year;
    this.model = model;
    this.maxSpeed = maxSpeed;
    this.position = new AircraftPositionModel(latitude, longitude);
    this.targeted = false;
  }


  updatePosition(lat: number, lng: number) {
    this.position.latitude = lat;
    this.position.longitude = lng;
  }
}
