/*
The aircraft position model provides the structure for aircraft position data.
*/

export class AircraftPositionModel {
  protected _latitude: number;
  protected _longitude: number;
  private _altitude: number;
  private _velocity: number;
  private _departedId: string;
  private _departedICAO: string;

  constructor(latitude: number, longitude: number, altitude?: number, velocity?: number, departedId?: string, departedICAO?: string) {
    this._latitude = latitude;
    this._longitude = longitude;
    this._altitude = altitude;
    this._velocity = velocity;
    this._departedId = departedId;
    this._departedICAO = departedICAO;
  }
  get latitude(): number {
    return this._latitude;
  }

  set latitude(value: number) {
    this._latitude = value;
  }

  get longitude(): number {
    return this._longitude;
  }

  set longitude(value: number) {
    this._longitude = value;
  }

  get altitude(): number {
    return this._altitude;
  }

  set altitude(value: number) {
    this._altitude = value;
  }

  get velocity(): number {
    return this._velocity;
  }

  set velocity(value: number) {
    this._velocity = value;
  }

  get departedId(): string {
    return this._departedId;
  }

  set departedId(value: string) {
    this._departedId = value;
  }

  get departedICAO(): string {
    return this._departedICAO;
  }

  set departedICAO(value: string) {
    this._departedICAO = value;
  }
}
