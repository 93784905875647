/*
The airspace model provides the structure for airspace data.
*/

import {LatLngLiteral} from '@agm/core';

export class AirspaceModel {
  private id: string;
  private icao: string;
  private boundary: Array<LatLngLiteral>;
  private color: string;
  private opacity: number;
  private airspaceClass: string;
  private minAltitude: number;
  public maxAltitude: number;
  private latitude: number;
  private longitude: number;

  constructor(boundary: Array<LatLngLiteral>, id: string, color: string, maxAlt: number){
    this.boundary = boundary;
    this.id = id;
    this.color = color;
    this.maxAltitude = maxAlt;
  }
}
