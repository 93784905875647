/*
The airspace data service provides external interactions with APIs to collect data to be displayed.
*/

import {Injectable} from '@angular/core';
import airspaceData from '../../../assets/Class_Airspace.json';

@Injectable({
  providedIn: 'root'
})
export class AirspaceDataService {

  constructor() { }

  getAirspaceData() {
    console.log(airspaceData.features);
    return airspaceData.features;
  }
}
