/*
The asset model provides the structure for asset data. It extends the aircraft model.
*/


import {AircraftModel} from './aircraft-model';
import {LocationModel} from './location-model';

export class AssetModel extends AircraftModel {
  private _available: boolean;
  private _location: LocationModel;
  private _dateAdded: string;
  private _dateUpdated: string;


  constructor(icao24: string, callSign: string, latitude: number, longitude: number, available: boolean) {
    super(icao24, callSign, latitude, longitude);
    this._available = available;
  }

  get available(): boolean {
    return this._available;
  }

  set available(value: boolean) {
    this._available = value;
  }

  get location(): LocationModel {
    return this._location;
  }

  set location(value: LocationModel) {
    this._location = value;
  }

  get dateAdded(): string {
    return this._dateAdded;
  }

  set dateAdded(value: string) {
    this._dateAdded = value;
  }

  get dateUpdated(): string {
    return this._dateUpdated;
  }

  set dateUpdated(value: string) {
    this._dateUpdated = value;
  }
}

