// The airspace component controls the view for airspace data.

import { Component, OnInit } from '@angular/core';
import {AirspaceModel} from '../../../model/models/airspace-model';
import {AirspaceDataService} from '../../../model/services/airspace-data.service';
import {LatLngLiteral} from '@agm/core';

@Component({
  selector: 'app-airspace',
  templateUrl: './airspace.component.html',
  styleUrls: ['./airspace.component.css']
})
export class AirspaceComponent implements OnInit {
  private airspaceArray: AirspaceModel[] = []; // maintains the array of airspaces to be displayed

  constructor(airspaceDataService: AirspaceDataService) {
    // retrives the airspace data from the data service. This should be more dynamic in the future
    const airspaceData = airspaceDataService.getAirspaceData();
    let tempLatLngLiterals: LatLngLiteral[] = []; // holds the LatLngLiterals array before building a new airspace model
    let tempColor = 'black'; // holds the color for the airspace model

    /* The below code iterates through the airspace data retrieved from the airspace service. Each airspace may have multiple rings
    * which have an array of coordinates that defines a polygon. For each ring of each airspace a new airspace model is created.
    *  The color for the airspace is based on the class of airspace. */
    for (const airspace of airspaceData) {
      for (const airspaceRing of airspace.geometry.rings) {
        for (const coords of airspaceRing) {
          tempLatLngLiterals.push({lat: coords[1], lng: coords[0]});
        }
        if (airspace.attributes.CLASS === 'B') {tempColor = 'blue'; }
        if (airspace.attributes.CLASS === 'C') {tempColor = 'magenta'; }
        if (airspace.attributes.CLASS === 'E') {tempColor = 'green'; }
        console.log(airspace);
        this.airspaceArray.push(new AirspaceModel(tempLatLngLiterals, airspace.attributes.ICAO_ID, tempColor,
          +airspace.attributes.UPPER_VAL));
        tempLatLngLiterals = [];
      }
      tempColor = 'black';
    }

    // The below sorts the airspace array by max altitude. It improves the visual because of the color and opacity.
    // A better solution is needed.
    this.airspaceArray.sort((a: AirspaceModel, b: AirspaceModel): number => {
      if (a.maxAltitude > b.maxAltitude) {
        return 1;
      }
      if (a.maxAltitude < b.maxAltitude) {
        return -1;
      }
      return 0;
    });
  }

  ngOnInit() {
  }

}
