import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { MapComponent } from './components/map/map.component';
import { AirspaceComponent } from './components/map/airspace/airspace.component';
import { AircraftComponent } from './components/map/aircraft/aircraft.component';
import { AssetComponent } from './components/map/asset/asset.component';
import {AgmCoreModule} from '@agm/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    AirspaceComponent,
    AircraftComponent,
    AssetComponent
  ],
  imports: [
    BrowserModule,
    AgmCoreModule.forRoot({apiKey: 'AIzaSyBvydbCQu56gTmUL6z17gWO2YzbxnUP5H8'}),
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
