/*
The aircraft data service provides external interactions with APIs to collect data to be displayed.
*/

import {Injectable, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AircraftDataService {
  private _latMin: number;
  private _latMax: number;
  private _lngMin: number;
  private _lngMax: number;
  private urlString: string;

  constructor(private httpClient: HttpClient) {
  }

  public getAircraft() {
    this.urlString = 'https://opensky-network.org/api/states/all?lamin=' + this._latMin + '&lomin=' + this._lngMin + '&lamax=' + this._latMax +
      '&lomax=' + this._lngMax;
    console.log('sent' + this._latMin + '&lomin=' + this._lngMin + '&lamax=' + this._latMax +
      '&lomax=' + this._lngMax);
    return this.httpClient.get(this.urlString);
  }


  get latMin(): number {
    return this._latMin;
  }

  set latMin(value: number) {
    this._latMin = value;
  }

  get latMax(): number {
    return this._latMax;
  }

  set latMax(value: number) {
    this._latMax = value;
  }

  get lngMin(): number {
    return this._lngMin;
  }

  set lngMin(value: number) {
    this._lngMin = value;
  }

  get lngMax(): number {
    return this._lngMax;
  }

  set lngMax(value: number) {
    this._lngMax = value;
  }
}
