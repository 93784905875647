// The map component controls the view for the map.

import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AircraftDataService} from '../../model/services/aircraft-data.service';
import {LatLng, LatLngBounds} from '@agm/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  @Input() mapType: string;
  @Input() latitude: number;
  @Input() longitude: number;
  @Input() zoom: number;
  private bounds: LatLngBounds;


  constructor(private aircraftDataService: AircraftDataService) {
  }

  boundsChange(event) {
    // This function is triggered any time the map is moved or the zoom changes. It updates the shared aircraft data service with new bounds
    // it's next API call
    this.aircraftDataService.latMax = event.getNorthEast().lat();
    this.aircraftDataService.latMin = event.getSouthWest().lat();
    this.aircraftDataService.lngMax = event.getNorthEast().lng();
    this.aircraftDataService.lngMin = event.getSouthWest().lng();
  }

  idle() {
    // This function is called when the map becomes idle after moving. It tells the shared aircraft data service to update the aircraft data
    this.aircraftDataService.getAircraft();
  }

  ngOnInit() {
  }

}
