// The aircraft component manages the view for aircraft, assets, and routes. These should be separated into different components later.

import {Component, Input, OnInit} from '@angular/core';
import {AircraftDataService} from '../../../model/services/aircraft-data.service';
import {timer} from 'rxjs';
import {AircraftModel} from '../../../model/models/aircraft-model';
import {AssetModel} from '../../../model/models/asset-model';
import {RouteModel} from '../../../model/models/route-model';
import {RouteArrayModel} from '../../../model/models/route-array-model';

@Component({
  selector: 'app-aircraft',
  templateUrl: './aircraft.component.html',
  styleUrls: ['./aircraft.component.css']
})
export class AircraftComponent implements OnInit {
  private aircraft: AircraftModel[] = []; // Holds all aircraft to be displayed
  private assets: AssetModel[] = []; // Holds all assets to be displayed
  private aircraftStates; // Holds the aircraft state data from the data service
  private routeArray: RouteArrayModel = new RouteArrayModel(); // Holds current routes to be displayed (needs to be adjust to allow for easily adding and removing)
  @Input() private aircraftJSON; // Accepts the JSON from the aircraft data service before being filtered to just the states.

  constructor(protected aircraftDataService: AircraftDataService) {
    // Adds two hardcoded assets
    this.assets.push(new AssetModel('111111', 'JOKER1', 42.366978, -71.022362, true));
    this.assets.push(new AssetModel('222222', 'BATMAN', 39, -92, true));
  }

  // Opens an info window if a marker is selected
  selectMarker(infoWindow) {
    infoWindow.open();
  }

  // Closes the info window
  closeWindow(infoWindow) {
    infoWindow.close();
  }

  // Calculates the distance between two geometric coordinates
  haversineFunction(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const earthRadius = 6371;
    const latDelta = this.degToRad(lat1 - lat2);
    const longDelta = this.degToRad(lon1 - lon2);
    const a = Math.sin(latDelta / 2) * Math.sin(latDelta / 2) + Math.cos(this.degToRad(lat2)) *
                        Math.sin(longDelta / 2) * Math.sin(longDelta / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadius * c;
  }

  // Converts degrees to radians for the haversineFunction
  degToRad(degree: number): number {
    return degree * (3.14159 / 180);
  }

  // Makes an aircraft targeted, which means a route will be created from the nearest asset.
  // The whole handling of targeting and routes needs to be revisited.
  changeTargetedValue(aircraft: AircraftModel) {
    aircraft.targeted = aircraft.targeted === false;
    if (aircraft.targeted === true) {
      this.calculateRoute(aircraft); } else { this.routeArray.removeRoute(aircraft); }
  }

  // Calculates the route from the best asset to the targeted aircraft.
  calculateRoute(aircraft: AircraftModel) {
    let shortestDistance = 100000;
    let bestAsset: AssetModel;
    let distance = 0;
    for (const asset of this.assets) {
      distance = this.haversineFunction(asset.position.latitude, asset.position.longitude,
                                        aircraft.position.latitude, aircraft.position.longitude);
      if ( distance < shortestDistance) {
        shortestDistance = distance;
        bestAsset = asset;
      }
    }
    bestAsset.available = false; // need to catch if no assets available
    this.routeArray.addRoute(new RouteModel(aircraft, bestAsset));
    aircraft.targeted = true;
  }

  ngOnInit() {
    // Subscribes to the aircraft data service with a timer that triggers the data service to request new data at least every 10 seconds.
    // The current data service is only accurate to 10 seconds, so faster calling is not efficient.
   timer(1000, 10000).subscribe(x => this.aircraftDataService.getAircraft().subscribe((data) => {
       this.aircraftJSON = data; // Accepts the JSON data
       this.aircraftStates = this.aircraftJSON.states; // Extracts the state information from the JSON data.
     // Checking time of the JSON data to current data should be added.

     // Goes through each aircraft state of the new aircraft data and updates an existing aircraft or adds a new one.
     // Problems arise with the targeting function when aircraft are destroyed when they leave the current view.
     // Aircraft not in the current view are removed to help improve performance.
     // Might add checking for targeted === true to avoid those being removed.
       for (const aircraft of this.aircraftStates) {
         if (this.aircraft.findIndex(x => x.icao24 === aircraft[0]) !== -1) {
           this.aircraft.find(x => x.icao24 === aircraft[0]).updatePosition(aircraft[6], aircraft[5]);
         } else {
           this.aircraft.push(new AircraftModel(aircraft[0], aircraft[1], aircraft[6], aircraft[5]));
         }
       }

       // Checks the new data with existing aircraft data.
     // If an aircraft is not contained in the new data, meaning it is no longer within the current view bounds, it is removed.
       for (const aircraft of this.aircraft) {
         if (aircraft.targeted === false && this.aircraftStates.findIndex(x => x[0] === aircraft.icao24) === -1) {
           this.aircraft = this.aircraft.filter(x => x.icao24 !== aircraft.icao24);
         }
       }

       this.routeArray.updateRoutes();
     }
   ));
  }

}
