/*
The route model provides the structure for route data.
*/


import {AssetModel} from './asset-model';
import {AircraftModel} from './aircraft-model';
import {LatLngLiteral} from '@agm/core';

export class RouteModel {
  private _pathLatLngs: LatLngLiteral[] = [];
  private _targetedAircraft: AircraftModel;
  private _interceptAsset: AssetModel;


  constructor(targetedAircraft: AircraftModel, interceptAsset: AssetModel) {
    this._pathLatLngs.push({lat: targetedAircraft.position.latitude, lng: targetedAircraft.position.longitude});
    this._pathLatLngs.push({lat: interceptAsset.position.latitude, lng: interceptAsset.position.longitude});
    this._targetedAircraft = targetedAircraft;
    this._interceptAsset = interceptAsset;
  }

  updatePath() {
    this.pathLatLngs = [];
    this._pathLatLngs.push({lat: this._targetedAircraft.position.latitude, lng: this._targetedAircraft.position.longitude});
    this._pathLatLngs.push({lat: this._interceptAsset.position.latitude, lng: this._interceptAsset.position.longitude});
  }


  get pathLatLngs(): LatLngLiteral[] {
    return this._pathLatLngs;
  }

  set pathLatLngs(value: LatLngLiteral[]) {
    this._pathLatLngs = value;
  }

  get targetedAircraft(): AircraftModel {
    return this._targetedAircraft;
  }

  set targetedAircraft(value: AircraftModel) {
    this._targetedAircraft = value;
  }

  get interceptAsset(): AssetModel {
    return this._interceptAsset;
  }

  set interceptAsset(value: AssetModel) {
    this._interceptAsset = value;
  }
}
